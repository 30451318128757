.copy {
  cursor: copy;
}
.pointer {
  cursor: pointer;
}
.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */

  cursor: grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.size-100 {
  height: 100% !important;
}

.border-right-top-4 {
  border-radius: 15px 50px 30px !important;
}
.border-radius-sm {
  border-radius: 0.5rem !important;
}
.border-radius-md {
  border-radius: 1rem !important;
}
.border-radius-lg {
  border-radius: 2rem !important;
}
.bg-black {
  background: linear-gradient(to right top, #343a40, #434b52, #676767) !important;
}
