.task-completed {
  margin-top: 5px;
  .task-bg {
    // background: linear-gradient(to right top, #3c164f, #8a3481, #a9449f);
    background: linear-gradient(to right top, #343a40, #434b52, #676767);
    color: #ffffff;
    width: 100%;
    border-radius: 5px;
  }
  .percentage-body {
    margin: 5px;
    width: 60px;
    height: 60px;
  }
}
