.tile {
  font-size: 18px;
  color: grey;
}
.tile:hover {
  font-size: 20px;
  font-weight: bolder;
  text-decoration: underline;
  color: black;
}
