.advance-textarea {
  height: 4em;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: all 0.5s ease;
}

.advance-textarea:focus {
  height: 14em;
}
.ck-editor__editable {
  height: 8rem !important;
  transition: all 0.5s ease;
  // line-height: 1.0em;
}
.ck-editor__editable:focus {
  height: 20em !important;
}
