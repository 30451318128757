@mixin avatar($size) {
  margin: 10px;
  width: $size !important;
  height: $size !important;
  text-align: center !important;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  .initials {
    padding: 0px;
    margin: 0px;
    position: relative;
    top: $size * 0.25;
    font-size: $size * 0.5;
    line-height: $size * 0.5;
    color: #fff;
    font-family: 'Courier New', monospace;
    font-weight: bold;
  }
}

.avatar-color-1 {
  background-color: #7ac943 !important;
}
.avatar-color-2 {
  background-color: #7070df !important;
}
.avatar-color-3 {
  background-color: #2ecdb5 !important;
}
.avatar-color-4 {
  background-color: #ba5dcd !important;
}
.avatar-color-5 {
  background-color: #c78b40 !important;
}
.avatar-color-6 {
  background-color: #c74e40 !important;
}
.avatar-circle {
  @include avatar(100px);
}
.avatar-circle-sm {
  @include avatar(50px);
}
.avatar-circle-lg {
  @include avatar(200px);
}
