.task {
  position: relative;
}
.task-date {
  padding: 10px 10px 0px 0px;
  float: right;
}
.task-body {
  // background-color: #dfe3ee !important;
  padding: 10px;
  color: #141823;
  font-size: medium;
  white-space: pre-wrap;
  // max-height: 50rem;
  margin-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.task-body::first-line {
  display: inline;
  color: #141823;
  text-decoration: underline;
  font-size: x-large;
  white-space: pre-wrap;
}

.task-border-top {
  border-top: 4px solid #dee2e6;
}
