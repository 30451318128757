.todo-delete {
  //   background-color: #4caf50; /* Green */
  border: none;
  color: black;
  padding: 0px 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  visibility: hidden;
  z-index: 9;
}
.todo-delete:hover {
  background-color: red;
  color: white;
}
