:root {
  --main-bg-color: #f7f7f7;
}
body,html {
  height: 100%;
}

html {
  font-size: 100% /* or 62.5% or whatever you like, it doesn't matter, it's just a browser fix, however "rem" units will be based on that value, so make it confortable for calculations */
}

body {
  font-family: 'Poppins', sans-serif !important; 
  background: var(--main-bg-color) !important;
}

/* Avatar CSS */
.md-avatar {
  vertical-align: middle;
  /* width: 35px; */
  height: 35px;
}
.md-avatar.size-1 {
  width: 40px;
  height: 40px;
}
.md-avatar.size-2 {
  width: 70px;
  height: 70px;
}
.md-avatar.size-3 {
  width: 90px;
  height: 90px;
}
.md-avatar.size-4 {
  width: 110px;
  height: 110px;
}