.onTop {
  z-index: 1060;
}
.todo {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.todo:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important;
}
.todo:last-child:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
}
.todo:hover .todo-delete {
  visibility: visible;
}
